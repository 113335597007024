<!--
 * @Author: pengyu
 * @Date: 2021-02-24 09:09:14
 * @LastEditors: 梁平贤
 * @LastEditTime: 2021-03-31 09:56:30
 * @Description: file content
-->
<template>
  <div>
    <div :class="{ 'half-screen': !fullScreen }" class="main-wrap">
      <!-- <top-nav v-if="!fullScreen"></top-nav> -->
      <div class="content" :class="{ 'need-half': !fullScreen }">
        <keep-alive include="MailList">
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import { mailSendService } from "@/api/mailBox/mailSend";
// import topNav from "./components/topNav";

export default {
  name: "App",
  // components: { topNav },
  data() {
    return {
      fullScreen: false
    };
  },
  computed: {
    ...mapGetters("mailBox", ["userMailConfig"])
  },
  watch: {
    $route(to) {
      const { fullScreen } = to.meta;
      this.fullScreen = fullScreen;
    }
  },
  mounted() {
    this.getMailConfig();
    this.queryUpladUrlParam();
  },
  created() {
    this.fullScreen = this.$route.meta.fullScreen;
  },
  methods: {
    ...mapMutations("mailBox", ["setUserMailConfig", "setUploadAction"]),
    closeMailDetail() {
      this.visible = false;
    },
    async queryUpladUrlParam() {
      const res = await mailSendService.queryUpladUrlParam();
      this.setUploadAction(
        `${res.server}?token=${res.token}&appKey=${res.appKey}&ts=${res.ts}`
      );
    },
    async getMailConfig() {
      // 因为使用了vuex-persistedstate所以每次的更新
      this.setUserMailConfig({
        mailNeedReceipt: "0",
        mailReceiptType: "3"
      });
      const res = await mailSettingService.queryUserConfigMultiple({
        type: "MAILUSERCONFIG"
      });
      if (res) {
        this.setUserMailConfig({
          ...this.userMailConfig,
          ...res
        });
      }
    }
  }
  // beforeRouteEnter(to, from, next) {
  //   const { meta } = to;
  //   console.log(meta);
  //   next();
  // }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
  // overflow: hidden;
}
.main-wrap {
  height: 100%;
  .content {
    height: 100%;
  }
}
.half-screen {
  width: 100%;
  height: 100%;
  .content {
    height: 100%;
    // background:#EBF0F4;
  }
  .need-half {
    height: calc(100% - 60px);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
