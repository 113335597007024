var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-info" }, [
    _vm._m(0),
    _c("div", { staticClass: "amount" }, [
      _c("span", [_vm._v(_vm._s(_vm.orderInfo.orderAmount))]),
      _vm._v(" 元 "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-item" }, [
      _c("span", [_vm._v("收款方 ：")]),
      _c("p", [_vm._v("湖南英富莱网络技术有限公司")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }