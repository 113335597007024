var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-mode-container" },
    [
      _c("div", { staticClass: "payment-mode" }, [
        _c(
          "div",
          {
            staticClass: "pay-item",
            class: { "active-item": _vm.activePay === "wechat" },
            on: {
              click: function ($event) {
                return _vm.changeMode("wechat")
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("../../../assets/images/wx.png"), alt: "" },
            }),
            _vm._m(0),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "pay-item",
            class: { "active-item": _vm.activePay === "ali" },
            staticStyle: { "margin-left": "54px", "margin-right": "54px" },
            on: {
              click: function ($event) {
                return _vm.changeMode("ali")
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/zfb.png"),
                alt: "",
              },
            }),
            _vm._m(1),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "pay-item",
            class: { "active-item": _vm.activePay === "union" },
            on: {
              click: function ($event) {
                return _vm.changeMode("union")
              },
            },
          },
          [
            _c("img", {
              attrs: { src: require("../../../assets/images/yl.png"), alt: "" },
            }),
            _vm._m(2),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activePay === "wechat" || _vm.activePay === "ali",
              expression: "activePay === 'wechat' || activePay === 'ali'",
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wx-ali-pay",
        },
        [
          _c("p", { staticClass: "pay-text" }, [
            _vm._v(" 请使用"),
            _c("span", { staticStyle: { color: "#f78528" } }, [
              _vm._v(_vm._s(_vm.activePay === "wechat" ? "微信" : "支付宝")),
            ]),
            _vm._v("扫码完成支付 "),
          ]),
          _c(
            "p",
            { staticClass: "period-text" },
            [
              _vm._v(" 二维码有效时长 "),
              _c("count-down", {
                staticStyle: { color: "#179eda" },
                attrs: { time: _vm.payInfo.payExpirePeriod * 60 * 1000 },
              }),
            ],
            1
          ),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.payInfo.qrImage,
                expression: "payInfo.qrImage",
              },
            ],
            staticClass: "qrcode",
            attrs: {
              src: "data:image/jpeg;base64," + _vm.payInfo.qrImage,
              alt: "",
            },
          }),
          _c("p", { staticClass: "refresh" }, [
            _vm._v(" 如无法支付二维码已过期请点击 "),
            _c(
              "span",
              { staticClass: "refresh-btn", on: { click: _vm.refresh } },
              [_vm._v("刷新")]
            ),
          ]),
        ]
      ),
      _c("payment-union", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activePay === "union",
            expression: "activePay === 'union'",
          },
        ],
        ref: "paymentUnion",
        on: { confirmPay: _vm.confirmUnionPay },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "checked-icon" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "checked-icon" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "checked-icon" }, [
      _c("i", { staticClass: "el-icon-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }