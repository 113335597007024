<template>
  <div class="payment-info">
    <div class="info-item">
      <span>收款方 ：</span>
      <p>湖南英富莱网络技术有限公司</p>
    </div>
    <div class="amount">
      <span>{{ orderInfo.orderAmount }}</span>  元
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentInfo",
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.payment-info {
  background: #f5f7fa;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 36px 0 18px 0;
  margin: 10px 0;
  .info-item {
    width: 50%;
    display: flex;
    font-size: 12px;
    color: #333;
    // text-indent: 40px;
    padding-left: 20px;
    margin-bottom: 22px;
    p {
      margin: 0;
      flex: 1;
      line-height: 16px;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal!important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
  }
  .download-link {
    color: #179eda;
    cursor: pointer;
    margin-right: 10px;
    text-decoration: none;
  }
  .amount {
    width: 45%;
    font-size: 12px;
    text-align: right;
    span {
      font-size: 16px;
      font-weight: bold;
      color: #f78528;
    }
  }
}
</style>
