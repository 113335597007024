var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "account-wrap" }, [
    _c(
      "div",
      { staticClass: "mod", on: { click: _vm.handleSwitch } },
      [
        _c("en-icon", {
          staticClass: "icon",
          attrs: {
            name: "shengchengyoujian",
            color: _vm.iconColor,
            size: "small",
          },
        }),
        _c(
          "span",
          {
            staticClass: "account-name",
            attrs: { title: _vm.account.mailFullAccount },
          },
          [_vm._v(_vm._s(_vm.account.mailFullAccount || ""))]
        ),
        _c("i", { class: ["iconfont iconfanhui", _vm.isOpen ? "open" : ""] }),
      ],
      1
    ),
    _c("div", { class: ["mod-group", _vm.isOpen ? "open" : ""] }, [
      _c(
        "div",
        {
          staticClass: "mod-item inbox",
          on: {
            click: (e) => {
              _vm.handleBoxClick(1, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: {
                    name: "shoujianxiang",
                    color: "#3e90fe",
                    size: "small",
                  },
                }),
                _c("span", [_vm._v("收件箱")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.inboxCount || "")),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mod-item draft",
          on: {
            click: (e) => {
              _vm.handleBoxClick(2, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: {
                    name: "caogaoxiang",
                    color: "#d69e79",
                    size: "small",
                  },
                }),
                _c("span", [_vm._v("草稿箱")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.draftCount || "")),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mod-item draft",
          on: {
            click: (e) => {
              _vm.handleBoxClick(6, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: { name: "daifasong", color: "#ffad2c", size: "small" },
                }),
                _c("span", [_vm._v("待发送")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.penddingCount || "")),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mod-item sended",
          on: {
            click: (e) => {
              _vm.handleBoxClick(3, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: { name: "yifasong", color: "#26c393", size: "small" },
                }),
                _c("span", [_vm._v("已发送")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.sendedCount || "")),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mod-item delete",
          on: {
            click: (e) => {
              _vm.handleBoxClick(4, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: { name: "shanchu", color: "#f76b6b", size: "small" },
                }),
                _c("span", [_vm._v("已删除")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.deletedCount || "")),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mod-item delete",
          on: {
            click: (e) => {
              _vm.handleBoxClick(5, e)
            },
          },
        },
        [
          _c("div", { staticClass: "mod-item-wrap" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("en-icon", {
                  attrs: {
                    name: "lajiyoujian",
                    color: "#d69e79",
                    size: "small",
                  },
                }),
                _c("span", [_vm._v("垃圾箱")]),
              ],
              1
            ),
            _c("div", { staticClass: "right" }, [
              _vm._v(_vm._s(_vm.account.dustbinCount || "")),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }