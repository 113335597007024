<!--
 * @Author: pengyu
 * @Date: 2021-03-01 16:13:35
 * @LastEditors: wuqi
 * @LastEditTime: 2021-09-07 18:57:56
 * @Description: 邮件列表
-->
<template>
  <div class="mail-list-wrap" :class="{'empty-wrap' : accountList.length === 0}">
    <div class="left-menu" v-if="accountList.length !== 0">
      <!-- 写邮件按钮 -->
      <div class="btn-group">
        <en-button
          class="btn-write"
          icon="xieyoujian"
          :iconSize="16"
          @click="toMailSend"
          >写邮件</en-button>
      </div>
      <!-- 未读邮件 目录 -->
      <div class="menu-group">
        <div
          class="menu-item noread active-item"
          @click.prevent="handleNoreadClick"
        >
          <div class="menu-item-wrap">
            <div class="left">
              <en-icon
                name="weiduyoujian"
                color="#FE7D67"
                size="small"
              ></en-icon>
              <span>未读邮件</span>
            </div>
            <div class="right" v-show="noreadNum > 0">
              {{ noreadNum }}
            </div>
          </div>
        </div>
        <div class="menu-item book" @click="handleBookClick">
          <div class="menu-item-wrap">
            <div class="left">
              <en-icon name="tongxunlu" color="#26c393" size="small"></en-icon>
              <span>通讯录</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 邮箱账户 -->
      <draggable
        class="account-group"
        group="field"
        v-model="accountList"
        @end="accountMove"
      >
        <account-mod
          v-for="(itm, idx) in accountList"
          :key="itm.id"
          :account="itm"
          :index="idx"
        ></account-mod>
      </draggable>
      <!-- 账户管理 -->
      <div class="btn-setting" @click="jumpToSetting">
        <en-button
          type="text"
          icon="shezhi"
          iconColor="#aab6c7"
          iconSize="14"
          >邮箱设置</en-button
        >
      </div>
    </div>
    <div class="right-list"  v-if="accountList.length !== 0">
      <div class="right-list-wrap" v-en-loading="loading">
        <div class="title-bar">
          <en-title-bar :title="mailTitle" :hasChecked="hasCheckedMail">
            <template slot="left">
              <el-popover
                v-if="hasCheckScreen"
                popper-class="checkbox-item-popover"
                placement="bottom"
                v-model="checkVisible"
              >
                <div class="btn-list-wrap">
                  <div
                    v-for="itm in screenBtns"
                    :key="itm.id"
                    :class="[
                      'btn-list-item',
                      itm.readFlag.toString() === seParams.readFlag.toString()
                        ? 'cur'
                        : '',
                    ]"
                    @click="handleScreenClick(itm.readFlag)">
                    <en-button
                      type="text"
                      :icon="itm.icon"
                      :iconSize="16"
                      :iconColor="itm.iconColor"
                      >{{ itm.name }}</en-button>
                  </div>
                </div>
                <div slot="reference">
                  <el-checkbox v-model="checkedAll" @change="handleCheckAll"
                    >全选</el-checkbox>
                  <i class="iconfont iconfanhui"></i>
                </div>
              </el-popover>
              <el-checkbox v-else v-model="checkedAll" @change="handleCheckAll"
                >全选</el-checkbox>
            </template>
            <template slot="right">
              <buttonMod
                :state="seParams.statuses"
                :mailType="mailType"
                :hasRelatBtn="!!mappingList.length"
              ></buttonMod>
              <status-popover v-if="seParams.folderType === 6"></status-popover>
            </template>
          </en-title-bar>
        </div>
        <div
          class="mail-container"
          :class="[mailList.length === 0 ? 'disflex' : '']"
        >
          <en-result
            type="NoData"
            v-show="mailList.length === 0 && !loading"
          ></en-result>
          <mail-mod
            v-for="(itm, idx) in mailList"
            :key="itm.id"
            :index="idx"
            :mailData="itm"
            :checkbox-visible="mailList.some((item) => item.checked)"
            usedFlg="list"
          ></mail-mod>
        </div>
        <en-pagination
          :pageModel="pageModel"
          @change="handlePageChanged"
          v-show="mailList.length"
        >
          <template slot="left">
            <div class="checked-tips" v-show="checkedNum > 0">
              <div>
                已选<span style="color: #3e90fe">{{ checkedNum }}</span
                >条数据
              </div>
              <en-button type="text" color="#3e90fe" @click="handleCleanChecked"
                >清空</en-button
              >
            </div>
          </template>
        </en-pagination>
      </div>
    </div>
    <div class="no-account" v-if="accountList.length === 0 && !loading">
      <div class="header">企业邮箱</div>
      <img
        class="icon-img"
        src="https://images.softfuxi.com/static/nodata11310.png"
        alt="无数据"
      />
      <p class="empty-text">
        您当前没有邮箱账号，点击
        <en-button type="primary" @click="jumpToSetting" text>添加邮箱账号</en-button>
      </p>
    </div>
    <mapping-dialog
      ref="mappingDialog"
      :mapping-list="mappingList"
      :relat-visible="relatVisible"
      @closeDialog="relatVisible = false"
      @handleRelatClick="handleRelatClick"
    ></mapping-dialog>
    <!-- 回执弹窗 -->
    <receipt-dialog ref="receiptDialog"></receipt-dialog>
    <!-- 邮件详情弹框 -->
    <mailDetail
      v-if="detailVisible"
      :needNext="needNext"
      :nextObj="nextObj"
      ref="mailDetail"
      :businessKey="businessKey"
      :seeflg="seeflg"
      :id="detailId"
      :visible="detailVisible"
      @close="detailVisible = false"
    ></mailDetail>
    <!-- 更换密码弹窗 -->
    <password-dialog ref="passwordDialog" @closeDialog="closeDialog" @submit="submit"></password-dialog>
    <Mapping v-if="addMappingflg" :id="mappingIds" :show="addMappingflg" :mappingList="mappingList" @toShow="addMappingflg = false"></Mapping>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { cloneDeep } from "lodash";
import draggable from "vuedraggable";
import { mailSettingService } from "@/api/mailBox/mailSetting";
import { mailBoxService } from "@/api/mailBox/mailList/index";
import enTitleBar from "../../components/enTitleBar";
import mailBtnFunc from "../../mixins/mailBtnFunc";
import mailDetail from "../MailDetail";
import PasswordDialog from "../../components/MailSetting/PasswordDialog";
import accountMod from "./components/AccountMod";
import buttonMod from "./components/buttonMod";
import mailMod from "./components/mailMod";
import MappingDialog from "./components/mappingDialog";
import statusPopover from "./components/statusPopover";
import ReceiptDialog from "./components/ReceiptDialog";
import Mapping from "../MailDetail/mapping"; // 新的映射弹框

export default {
  name: "MailList",
  data() {
    return {
      loading: false,
      mailTitle: "未读邮件",
      mailType: 0,
      accountList: [], // 邮箱账户列表
      checkVisible: false,
      detailVisible: false, // 详情 弹框
      detailId: "", // 邮件详情id
      noreadNum: 0, // 未读邮件总数
      mailList: [],
      seParams: {
        mailAccount: "",
        type: 1,
        statuses: 1,
        readFlag: 1,
        pageNo: 1,
        pageSize: 10,
        folderType: "",
        // 不是未读邮件不需要statuses 和 type
        unread: true
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0
      },
      mappingList: [], // 映射功能
      mappingId: {},
      screenBtns: [
        {
          id: "all",
          name: "全部",
          icon: "yixuan",
          iconColor: "#f76b6b",
          readFlag: ""
        },
        {
          id: "read",
          name: "已读",
          icon: "yidu",
          iconColor: "#26c393",
          readFlag: "0"
        },
        {
          id: "noread",
          name: "未读",
          icon: "yijianyuedu",
          iconColor: "#f76b6b",
          readFlag: "1"
        }
      ],
      // 邮件详情是否显示操作按钮
      seeflg: true,
      // 从业务建模带过来的业务建模id 邮件详情需要
      businessKey: "",
      errorAccountList: [],
      // 新的 映射弹框
      addMappingflg: false,
      // 新的 映射弹框 当前页面选中的ids
      mappingIds: "",
      // 是否有翻页
      needNext: true,
      // 翻页数据
      nextObj: {}
    };
  },
  provide() {
    return {
      $mailList: this
    };
  },
  watch: {
    relatVisible(nVal) {
      if (nVal) {
        this.initMappingList();
      } else {
        this.mappingId = "";
      }
    },
    $route(newVal) {
      // 路由变化 关闭弹框 zjh
      this.detailVisible = false;
      this.addMappingflg = false;
      if (newVal.name === "List") {
        this.init();
      }
      if (newVal.query.id) {
        this.handleMailClick();
      }
    }
  },
  mixins: [mailBtnFunc],
  computed: {
    hasCheckedMail() {
      return this.mailList.filter((itm) => itm.checked).length > 0;
    },
    checkedAll: {
      get() {
        return this.mailList.filter((itm) => !itm.checked).length === 0;
      },
      set() {}
    },
    checkedNum() {
      return this.mailList.filter((itm) => itm.checked).length;
    },
    hasCheckScreen() {
      // 收件箱和已删除 有全选筛选（已读未读）
      return (
        this.seParams.mailAccount
        && (Number(this.seParams.statuses) === 1
          || Number(this.seParams.statuses) === 3)
      );
    }
  },
  mounted() {
    this.init();
    // 如果有id 要弹框详情
    if (this.$route.query.id) {
      // readOnly 只读邮件 不显示按钮 这里打开就是 穿透过来的 不需要按钮
      this.seeflg = this.$route.query.seeflg !== "readOnly";
      this.detailId = this.$route.query.id; // 邮件详情id
      this.businessKey = this.$route.query.businessKey; // 穿透业务建模id
      this.needNext = false; // 穿透不需要翻页
      this.detailVisible = true; // 详情 弹框
    } else {
      this.receiveMail();
    }
    // 查询映射数据
    this.initMappingList();
  },
  components: {
    accountMod,
    enTitleBar,
    buttonMod,
    mailMod,
    mailDetail,
    statusPopover,
    ReceiptDialog,
    MappingDialog,
    PasswordDialog,
    Mapping,
    draggable
  },
  methods: {
    closeDialog() {
      this.errorAccountList.shift();
      setTimeout(() => {
        if (this.errorAccountList.length && this.$route.name === "List") {
          this.$refs.passwordDialog.show(this.errorAccountList[0]);
        }
      }, 500);
    },
    async accountMove() {
      await mailSettingService.sortMailAccountStatusByGeneral({
        ids: this.accountList.map((item) => item.id).join(",")
      });
    },
    async submit(data) {
      console.log(data);
      try {
        const res = await mailSettingService.updateMailAccountByGeneral(data);
        console.log(res);
        this.$refs.passwordDialog.hide();
        if (this.isSingleValidtorAccount) {
          this.queryMailList();
        }
      } catch (error) {
        this.$refs.passwordDialog.btnLoading = false;
      }
    },
    async receiveMailByAccount(mailAccount) {
      this.isSingleValidtorAccount = true;
      this.receiveMail(mailAccount);
    },
    async receiveMail(mailAccount) {
      const res = await mailSettingService.receiveMail({ mailAccount });
      this.errorAccountList = res || [];
      if (this.errorAccountList.length && this.$route.name === "List") {
        this.$refs.passwordDialog.show(this.errorAccountList[0]);
      }
    },
    passwordError() {
      const h = this.$createElement;
      this.$msgbox({
        title: "",
        closeOnClickModal: false,
        message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
          h("en-icon", { attrs: { name: "tishi", color: "#f7bf27", size: "36px" } }),
          h("div", { style: { marginLeft: "10px" } }, [h("p", {}, "多个账号密码错误"), h("span", {
            style: { color: "#636c78", marginTop: "4px", display: "inline-block" }
          }, "您的邮件账号“12345@qq.com”“54321@163.com”密码错误，请在账号管理处重新输入密码.")])
        ]),
        confirmButtonClass: "mail-btn-warning",
        confirmButtonText: "前往账号管理",
        callback: (action) => {
          if (action === "confirm") {
            console.log(this);
          }
        }
      });
    },
    receiveMailMsg() {
      const h = this.$createElement;
      this.messageInstance = this.$message({
        duration: 0,
        type: "error",
        customClass: "mail-error-message",
        message: h("p", null, [
          h("p", { style: "fontSize: 12px;maxWidth: 370px;lineHeight: 18px;marginTop: -3px;" },
            "邮箱账号为“12345@qq.com”“54321@163.com”的审批发送有邮件发送失败，请在对应账号的待发送列表查看。"),
          h("span", {
            style: "color: #3e90fe;fontSize: 12px;float: right;cursor: pointer;",
            on: {
              click: () => {
                this.messageInstance.close();
              }
            }
          }, "我知道了")
        ])
      });
    },
    // 跳转至邮箱设置
    jumpToSetting() {
      this.$router.push("/mailbox/mailSetting/accountManage");
    },
    // 发送回执
    receiptHandler(data) {
      this.$refs.receiptDialog.show(data);
    },
    /**
     * @description 初始化
     */
    init() {
      this.queryAccountList();
      this.queryMailList();
    },
    /**
     * @description 查询 未读邮件 邮件总数
     */
    async queryMailTotalNum() {
      const pa = {
        mailAccount: "",
        type: 1,
        statuses: 1,
        readFlag: 1
      };

      const rsp = await mailBoxService.countMail(pa);
      this.noreadNum = rsp;
    },
    /**
     * @description 查询邮箱账户
     */
    async queryAccountList() {
      this.cacheAccountList = cloneDeep(this.accountList);
      const rsp = await mailBoxService.getMailAccount();
      this.accountList = (rsp || []).filter((itm) => itm.status === 0);
      this.accountList = this.accountList.map((item) => {
        const cacheObj = this.cacheAccountList.find((account) => account.id === item.id);
        if (cacheObj) {
          return {
            ...item,
            ...cacheObj
          };
        }
        return item;
      });
      if (this.accountList.length) {
        this.$store.commit("mailBox/_MxChangeVuexState", {
          mailAccount: this.accountList[0].mailFullAccount
        });
      } else {
        this.$store.commit("mailBox/_MxChangeVuexState", { mailAccount: "" });
      }
    },
    /**
     * @description 查询邮件列表
     */
    async queryMailList() {
      this.mailList = [];
      try {
        this.loading = true;
        Object.assign(this.seParams, this.pageModel);
        let params = { ...this.seParams };
        if (this.seParams.unread === false) {
          const { statuses, type, ...rest } = this.seParams;
          params = rest;
        }
        const rsp = await mailBoxService.queryMailList(params);
        this.loading = false;
        this.mailList = rsp.records;
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
        this.queryMailTotalNum();
        if (this.seParams.mailAccount) {
          // 如果是查询的某个邮箱的列表， 还需更新该邮箱的 各箱子数量
          const ml = this.accountList.filter(
            (itm) => itm.mailFullAccount === this.seParams.mailAccount
          )[0];
          if (ml) {
            this.queryMailBoxCount(ml.id);
          }
        }
      } catch (error) {
        Message(error);
        this.loading = false;
      }
    },
    /**
     * @description 重置分页参数
     */
    resetPageModel() {
      Object.assign(this.pageModel, this.$options.data().pageModel);
    },
    /**
     * @description 更新单个邮箱的  各箱子邮件数
     */
    async queryMailBoxCount(id) {
      if (!id) return;
      const mail = this.accountList.filter((itm) => itm.id === id)[0];
      if (mail) {
        const pa = { mailAccount: mail.mailFullAccount };
        const rsp = await mailBoxService.countMailGroup(pa);
        if (rsp) {
          // Object.assign(mail, rsp);
          this.$set(mail, "inboxCount", rsp.inboxCount);
          this.$set(mail, "sendedCount", rsp.sendedCount);
          this.$set(mail, "draftCount", rsp.draftCount);
          this.$set(mail, "deletedCount", rsp.deletedCount);
          this.$set(mail, "penddingCount", rsp.penddingCount);
          this.$set(mail, "dustbinCount", rsp.dustbinCount);
        }
      }
    },
    /**
     * @description 未读邮件
     */
    handleNoreadClick(e) {
      const flg = this.setElementClass(e, "menu-item");
      this.mailTitle = "未读邮件";
      this.mailType = 0;
      if (flg) {
        // 查询未读邮件列表
        const pa = {
          mailAccount: "",
          type: 1,
          statuses: 1,
          readFlag: 1,
          folderType: "",
          approveStatus: "",
          unread: true
        };
        Object.assign(this.seParams, pa);
        this.resetPageModel();
        this.queryMailList();
      }
    },
    /**
     * @description 设置选中样式
     */
    setElementClass(e, className) {
      const { target } = e;
      const ele = this.findUpElement(target, className);
      if (ele.classList.contains("active-item")) {
        return false;
      }
      const charts = document.querySelector(".active-item");
      charts && charts.classList.remove("active-item");
      ele.classList.add("active-item");
      return true;
    },
    /**
     * @description 查找指定target
     */
    findUpElement(target, className) {
      if (target.classList.contains(className)) {
        return target;
      }
      return this.findUpElement(target.parentNode, className);
    },
    /**
     * @description 通讯录
     */
    handleBookClick() {
      // const flg = this.setElementClass(e, "menu-item");
      // if (flg) {
      // 路由跳转通讯录
      this.$router.push("/mailbox/addressBook");
      // }
    },
    /**
     * @description 分页器事件
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryMailList();
    },
    /**
     * @description 筛选
     * @param radFlag ""全部  "0"已读  "1"未读
     */
    handleScreenClick(readFlag) {
      this.checkVisible = false;
      this.seParams.readFlag = readFlag;
      this.queryMailList();
    },
    /**
     * @description 全选
     */
    handleCheckAll(value) {
      this.mailList.forEach((itm) => {
        this.$set(itm, "checked", value);
      });
    },
    /**
     * @description 清空已选
     */
    handleCleanChecked() {
      this.mailList.forEach((itm) => {
        this.$set(itm, "checked", false);
      });
    },
    /**
     * @description 写邮件
     */
    toMailSend() {
      this.$router.push("/mailbox/mailSend");
    },
     handleMailClick() {
      const {
        readFlag, status, id, receiptFlag
      } = this.$router.query;
      // 未读邮件以及需要回执 详情以判断
      if (readFlag === 1 && receiptFlag === 0 && status === 1) {
        // this.receiptHandler(this.mailData);
      }
      if (readFlag === 1) {
        // 未读邮件需要先变成已读
        this.updateMailRead(id, 0, true, true);
      }
      if (status === 2) { // 草稿
        this.$router.push({ path: "/mailBox/mailSend", query: { mailType: "draft", mailId: id } });
        return;
      }
      // 进入详情
      // 获取点击的第几个 zjh
      let mailListIndex = 1;
      this.mailList.forEach((element, index) => {
        if (element.id === id) {
          mailListIndex = index + 1;
        }
      });
      this.detailId = id;
      this.needNext = true;
      this.nextObj = {
        pageModel: this.pageModel,
        seParams: this.seParams,
        url: this.usedFlg === "list" ? "queryMailList" : "searchMail",
        index: mailListIndex
      };
      this.detailVisible = true;
    }
  }
};
</script>

<style lang="scss">
.no-account {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  .header {
    height: 48px;
    line-height: 48px;
    text-align: left;
    text-indent: 20px;
    color: #333333;
    font-size: 14px;
    border-bottom: 1px solid #e8ecf2;
  }
  .icon-img {
    height: 106px;
    width: 110px;
    margin-top: 250px;
  }
  .empty-text {
    font-size: 14px;
    color: #91a1b7;
  }
}
.mail-error-message {
  align-items: flex-start;
}
.mail-btn-warning {
  background: #f7bf27 !important;
  border-color: #f7bf27 !important;
}
</style>
<style lang="scss" scoped>
@import "@/styles/vars.scss";

.mail-list-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 12px;
}
.empty-wrap {
  background: #e8ecf2;
  padding: 10px;
}
.left-menu {
  width: 194px;
  height: 100%;
  background: #fff;
  // padding: 20px;
  .btn-group {
    width: 100%;
    padding: 20px 20px 10px 20px;
    .btn-write {
      width: 100%;
      background: #3e90fe;
      color: #fff;
      span {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }
  .menu-group {
    // padding: 0 20px;
    .menu-item {
      padding: 0 20px;
    }
    .menu-item-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      // cursor: pointer;
      .left {
        .en-icon {
          vertical-align: middle;
        }
        span {
          margin-left: 5px;
          vertical-align: middle;
        }
      }
      .right {
        position: relative;
        color: #636c78;
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #f76b6b;
          position: absolute;
          top: 0;
        }
      }
    }

    .noread:hover {
      background: rgba(#ff7e66, 0.08);
      cursor: pointer;
    }
    .noread.active-item {
      @include hoverBg(#ff7e66, 50px);
    }
    .book:hover {
      background: rgba(#26c393, 0.08);
      cursor: pointer;
    }
    .book.active-item {
      @include hoverBg(#26c393, 50px);
    }
    .book .menu-item-wrap {
      border-bottom: 1px solid #e8ecf2;
    }
  }
  .account-group {
    height: calc(100% - 208px);
    overflow: hidden;
    overflow-y: auto;
    // @include hscrollbar;
  }
  .btn-setting {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f6f6f8;
  }
}
.right-list {
  flex: 1;
  background: #ecf1f7;
  padding: 10px;
  overflow: hidden;
  .en-title-bar {
    & /deep/ .el-popover__reference {
      .el-checkbox__label {
        font-size: 12px;
      }
      .iconfanhui {
        font-size: 10px !important;
        color: #a9b5c6;
        display: inline-block;
        margin-left: 5px;
        transform: rotate(-90deg);
      }
    }
  }
  .right-list-wrap {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 3px;
  }
  .mail-container {
    width: 100%;
    height: calc(100% - 105px);
    border-bottom: 1px solid #e8ecf2;
    overflow-y: auto;
    .en-result {
      margin-top: -80px;
    }
  }
  .disflex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
  }
  .pagination {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .checked-tips {
      padding-left: 20px;
      display: flex;
      align-items: center;
      .en-button {
        margin-left: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
//全选框 下拉按钮
.hover-item {
  background: #4895df !important;
  .en-button {
    color: #ffffff;
    .en-icon-gap {
      fill: rgb(255, 255, 255) !important;
    }
  }
}
.checkbox-item-popover {
  padding: 2px 0;
  .btn-list-wrap {
    .btn-list-item {
      cursor: pointer;
      text-indent: 20px;
      font-size: 12px;
      &.cur {
        @extend .hover-item;
      }
      &:hover {
        @extend .hover-item;
      }
    }
  }
}
// 关联应用弹框
.relat-temp-alert {
  .left-title {
    > span {
      vertical-align: middle;
    }
    .en-icon {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .el-dialog__body {
    padding-bottom: 20px;
  }
  .el-dialog__footer {
    font-size: 12px;
  }
  .content {
    height: 100px;
    font-size: 12px;
    .flex-item {
      display: flex;
      align-items: center;
      label {
        width: 80px;
      }
    }
  }
  .en-select {
    width: 100%;
  }
}
//关联应用提示
.relat-temp-tip {
  width: 568px;
  &.is-dark {
    background: #676976 !important;
  }
}
</style>
