<template>
  <div class="payment-mode-container">
    <div class="payment-mode">
      <div class="pay-item" @click="changeMode('wechat')" :class="{'active-item': activePay === 'wechat'}">
        <img src="../../../assets/images/wx.png" alt="">
        <span class="checked-icon">
          <i class="el-icon-check"></i>
        </span>
      </div>
      <div class="pay-item" style="margin-left: 54px;margin-right: 54px;" @click="changeMode('ali')" :class="{'active-item': activePay === 'ali'}" >
        <img src="../../../assets/images/zfb.png" alt="">
        <span class="checked-icon">
          <i class="el-icon-check"></i>
        </span>
      </div>
      <div class="pay-item" @click="changeMode('union')" :class="{'active-item': activePay === 'union'}">
        <img src="../../../assets/images/yl.png" alt="">
        <span class="checked-icon">
          <i class="el-icon-check"></i>
        </span>
      </div>
    </div>
    <div class="wx-ali-pay" v-show="activePay === 'wechat' || activePay === 'ali'" v-loading="loading">
      <p class="pay-text">
        请使用<span style="color: #f78528;">{{activePay === 'wechat' ? '微信' : '支付宝'}}</span>扫码完成支付
      </p>
      <p class="period-text">
        二维码有效时长
        <count-down style="color: #179eda;" :time="payInfo.payExpirePeriod * 60 * 1000"></count-down>
      </p>
      <img class="qrcode" v-show="payInfo.qrImage" :src="'data:image/jpeg;base64,' + payInfo.qrImage" alt="">
      <p class="refresh">
        如无法支付二维码已过期请点击
        <span class="refresh-btn" @click="refresh">刷新</span>
      </p>
    </div>
    <payment-union ref="paymentUnion" v-show="activePay === 'union'" @confirmPay="confirmUnionPay"></payment-union>
  </div>
</template>

<script>
import { sysPaymentService } from "@/api/payment";
import CountDown from "../components/CountDown";
import PaymentUnion from "./PaymentUnion";

export default {
  name: "PaymentMode",
  components: {
    CountDown,
    PaymentUnion
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
  },
  methods: {
    async confirmUnionPay(data) {
      console.log(data);
      const res = await sysPaymentService.sysPayPostZjPayUnionPay({
        ...data,
        orderId: this.$route.query.orderId
      });
      window.open(res.forwardUrl);
      this.$refs.paymentUnion.loading = false;
    },
    async changeMode(mode) {
      const { orderId } = this.$route.query;
      this.activePay = mode;
      this.payInfo.payExpirePeriod = 0;
      if (mode === "wechat") {
        try {
          this.loading = true;
          this.payInfo = await sysPaymentService.sysPayPostZjPayWeChatPay({ orderId });
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
      if (mode === "ali") {
        try {
          this.loading = true;
          this.payInfo = await sysPaymentService.sysPayPostZjPayAlipay({ orderId });
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      }
    },
    refresh() {
      this.changeMode(this.activePay);
    }
  },
  data() {
    return {
      activePay: "wechat",
      loading: false,
      payInfo: {
        qrImage: "",
        qrImageUrl: "",
        payExpirePeriod: 60
      }
    };
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.payment-mode-container {
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e8ecf2;
  .payment-mode {
    display: flex;
    margin-top: 72px;
    margin-bottom: 62px;
    justify-content: center;
    .pay-item {
      position: relative;
      width: 200px;
      height: 72px;
      border-radius: 4px;
      border: 1px solid #e8ecf2;
      // margin-right: 54px;
      overflow: hidden;
      cursor: pointer;
      .checked-icon {
        display: none;
        position: absolute;
        right: -20px;
        bottom: -27px;
        width: 40px;
        height: 40px;
        transform: rotate(45deg);
        background: #179eda;
        color: #fff;
        .el-icon-check {
          position: absolute;
          left: 0px;
          top: 8px;
          transform: rotate(-45deg);
          font-size: 12px;
        }
      }
    }
    .active-item {
      border-color: #a7cbef;
      background: #ecf7ff;
      .checked-icon {
        display: block;
      }
    }
  }
  .wx-ali-pay {
    .pay-text {
      color: #333333;
      font-size: 18px;
    }
    .period-text {
      color: #333333;
      font-size: 12px;
      margin-top: 12px;
    }
    .qrcode {
      width: 204px;
      height: 204px;
      margin: 26px 0 0 0;
    }
    .refresh {
      font-size: 12px;
      color: #333;
      margin-bottom: 100px;
      .refresh-btn {
        color: #179eda;
        cursor: pointer;
      }
    }
  }
}
</style>
