<template>
  <div class="payment-header">
    <img :src="channelLogo" alt="" class="logo">
    <div class="divider"></div>
    <span class="title">充值金额</span>
  </div>
</template>

<script>
export default {
  name: "PaymentHeader",
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    channelLogo() {
       const channelLogo = this.orderInfo.tenantLogo?.channelLogo;
      // eslint-disable-next-line global-require
      return channelLogo || require("@/assets/images/logoall.png");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.payment-header {
  height: 60px;
  border-bottom: 1px solid #e8ecf2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .logo {
    width: 160px;
    height: 50px;
    margin-left: 10px;
  }
  .divider {
    width: 1px;
    height: 30px;
    background: #e8ecf2;
    margin-right: 18px;
  }
  .title {
    font-size: 14px;
    color: #333333;
    font-weight: bold;
  }
}
</style>
