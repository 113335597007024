<!--
 * @Author: pengyu
 * @Date: 2021-03-03 17:36:28
 * @LastEditors: pengyu
 * @LastEditTime: 2021-04-26 18:13:20
 * @Description: 邮件账户
-->
<template>
  <div class="account-wrap">
    <div class="mod" @click="handleSwitch">
      <en-icon class="icon" name="shengchengyoujian" :color="iconColor" size="small"></en-icon>
      <span class="account-name" :title="account.mailFullAccount">{{account.mailFullAccount || ""}}</span>
      <i :class="['iconfont iconfanhui', isOpen ? 'open' : '']"></i>
    </div>
    <div :class="['mod-group', isOpen ? 'open' : '']">
      <div class="mod-item inbox" @click="(e) => {handleBoxClick(1, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="shoujianxiang" color="#3e90fe" size="small"></en-icon>
            <span>收件箱</span>
          </div>
          <div class="right">{{account.inboxCount || ""}}</div>
        </div>
      </div>
      <div class="mod-item draft" @click="(e) => {handleBoxClick(2, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="caogaoxiang" color="#d69e79" size="small"></en-icon>
            <span>草稿箱</span>
          </div>
          <div class="right">{{account.draftCount || ""}}</div>
        </div>
      </div>
      <div class="mod-item draft" @click="(e) => {handleBoxClick(6, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="daifasong" color="#ffad2c" size="small"></en-icon>
            <span>待发送</span>
          </div>
          <div class="right">{{account.penddingCount || ""}}</div>
        </div>
      </div>
      <div class="mod-item sended" @click="(e) => {handleBoxClick(3, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="yifasong" color="#26c393" size="small"></en-icon>
            <span>已发送</span>
          </div>
          <div class="right">{{account.sendedCount || ""}}</div>
        </div>
      </div>
      <div class="mod-item delete" @click="(e) => {handleBoxClick(4, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="shanchu" color="#f76b6b" size="small"></en-icon>
            <span>已删除</span>
          </div>
          <div class="right">{{account.deletedCount || ""}}</div>
        </div>
      </div>
      <div class="mod-item delete" @click="(e) => {handleBoxClick(5, e)}">
        <div class="mod-item-wrap">
          <div class="left">
            <en-icon name="lajiyoujian" color="#d69e79" size="small"></en-icon>
            <span>垃圾箱</span>
          </div>
          <div class="right">{{account.dustbinCount || ""}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AccountMod",
  props: {
    index: Number,
    account: {
      type: Object,
      default: () => {}
    }
  },
  inject: ["$mailList"],
  computed: {
    iconColor() {
      const color = ["#3e90fe", "#26c393", "#ffad2c", "#9367eb"];
      const num = this.index || 0;
      const yu = num % 4;
      return color[yu];
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    /**
     * @description 展开收起邮箱
    */
    handleSwitch() {
      this.isOpen = !this.isOpen;
      if (this.isOpen && this.account.inboxCount === undefined) {
        this.$mailList.queryMailBoxCount(this.account.id);
      }
    },
    /**
     * @description 邮件各箱子点击
     * @param id  1:收件箱 2：草稿箱 3：已发送 4：已删除
     * @param e   event
    */
    handleBoxClick(id, e) {
      const flg = this.$mailList.setElementClass(e, "mod-item");
      if (flg) {
        const pa = { mailAccount: this.account.mailFullAccount, readFlag: "", unread: false };
        let txt = "";
        switch (id) {
          case 1: // 收件箱
            pa.type = 1;
            pa.statuses = "1";
            pa.folderType = 1;
            txt = "收件箱";
            break;
          case 2: // 草稿箱
            pa.type = 2;
            pa.statuses = "2";
            pa.folderType = 3;
            txt = "草稿箱";
            break;
          case 3: // 已发送
            pa.type = 2;
            pa.statuses = "1,4";
            pa.folderType = 2;
            txt = "已发送";
            break;
          case 4: // 已删除
            pa.type = "";
            pa.statuses = "3";
            pa.folderType = 4;
            txt = "已删除";
            break;
          case 5: // 垃圾箱
            pa.type = 5;
            pa.statuses = "5";
            pa.folderType = 5;
            txt = "垃圾箱";
            break;
          case 6: // 待发送
            pa.type = "";
            pa.statuses = "";
            pa.folderType = 6;
            txt = "待发送";
            break;
          default:
            break;
        }
        if (id === 1 && this.account.createType === 1) {
          this.$mailList.receiveMailByAccount(this.account.mailFullAccount);
        }
        pa.approveStatus = "";
        Object.assign(this.$mailList.seParams, pa);
        this.$mailList.mailTitle = txt;
        this.$mailList.mailType = pa.type;
        this.$mailList.resetPageModel();
        if (id === 6) {
          this.$mailList.pageModel.pageSize = 20;
        }
        this.$mailList.queryMailList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.account-wrap {
  width: 100%;
  &:hover {
    background: #f5f9fd;
  }
}
.mod {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .account-name{
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .iconfanhui {
    font-size: 10px;
    transform: rotate(180deg);
    &.open {
      transform: rotate(-90deg);
      color: #3e90fe;
    }
  }
}
.mod-group {
  display: none;
  &.open {
    display: block;
  }
}
.mod-item {
  position: relative;
  &.active-item{
    @include hoverBg(#3e90fe, 48px);
  }
  .mod-item-wrap {
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding: 0 20px 0 40px;
    .left {
      .en-icon {
        margin-right: 5px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  &:hover {
    background: rgba(#3e90fe, 0.08);
    cursor: pointer;
  }
}
</style>
