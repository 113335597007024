var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "en-title-bar" },
    [
      !_vm.hasChecked
        ? _c("div", { staticClass: "left" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm.hasChecked ? _vm._t("left") : _vm._e(),
      _vm._t("right"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }