var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-header" }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: _vm.channelLogo, alt: "" },
    }),
    _c("div", { staticClass: "divider" }),
    _c("span", { staticClass: "title" }, [_vm._v("充值金额")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }