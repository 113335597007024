var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        "popper-class": "status-screen-popover",
        placement: "bottom-start",
        trigger: "hover",
        width: 160,
        offset: -90,
      },
    },
    [
      _c(
        "template",
        { slot: "reference" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-screen",
              attrs: {
                type: "white",
                size: "small",
                icon: "iconfont iconshaixuan",
              },
            },
            [_vm._v(_vm._s(_vm.btnName))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popover-content" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.res,
                callback: function ($$v) {
                  _vm.res = $$v
                },
                expression: "res",
              },
            },
            _vm._l(_vm.list, function (itm, idx) {
              return _c("el-checkbox", { key: idx, attrs: { label: itm.id } }, [
                _vm._v(_vm._s(itm.name)),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }