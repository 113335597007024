import BaseService from "../../BaseService";

class MailSendService extends BaseService {
  constructor(http) {
    super("", http);
  }

  // 联系人模糊查询
  searchMailAddress(params) {
    return this.http.post("/searchMailAddress", params);
  }

  // 发送邮件
  sendMail(params) {
    return this.http.post("/sendMail", params);
  }

  // 查询公司组织机构树
  queryObjectTreeExtAccess(params) {
    return this.http.post("/queryObjectTreeExtAccess", params);
  }

  // 查询公司联系人
  /**
   *tenantId
    userId
    id
    levelCode
    pageNo
    pageSize
   * @param {*} params
   */
  searchCompanyContact(params) {
    return this.http.post("/searchCompanyContact", params);
  }

  // 查询联系人
  searchContact(params) {
    return this.http.post("/searchContact", params);
  }

  // 查询个人邮箱账户
  getMailAccount(params) {
    return this.http.post("/getMailAccount", params);
  }

  // 查询邮件详情
  queryMailDetail(params) {
    return this.http.post("/queryMailDetail", params);
  }

  // 联系人分类查询
  getContactClassifies(params) {
    return this.http.post("/getContactClassifies", params);
  }

  // 获取联系人列表
  getContactList(params) {
    return this.http.post("/getContactList", params);
  }

  // 根据ids获取公司联系人详情
  getCompanyContactsByIds(params) {
    return this.http.post("/getCompanyContactsByIds", params);
  }

  // 根据ids获取个人联系人详情
  getPersonalContactsByIds(params) {
    return this.http.post("/getPersonalContactsByIds", params);
  }

  // 业务建模生成的邮件信息
  mdGenerateMailInfo(params) {
    return this.http.post("/mdGenerateMailInfo", params);
  }

  // 查询上传接口
  queryUpladUrlParam(params) {
    return this.http.post("/queryUpladUrlParam", params);
  }

  // 发送回执邮件
  receiptMail(params) {
    return this.http.post("/receiptMail", params);
  }

  // 我的文件夹目录
  queryFolderList(params) {
    return this.http.post("/queryFolderList", params);
  }

  // 我的文件夹
  fiQueryMyFolderFiles(params) {
    return this.http.post("/fiQueryMyFolderFiles", params);
  }

  // 根据id获取文件夹详情
  fiGetFileInfoByIds(params) {
    return this.http.post("/fiGetFileInfoByIds", params);
  }

  // 菜单权限
  queryMenuAuth(params = { menuId: "190426114931174467" }) {
    return this.http.post("queryMenuAuth", params);
  }
}

export default MailSendService;
