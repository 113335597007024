<!--
 * @Author: pengyu
 * @Date: 2021-02-24 14:31:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-25 15:52:40
 * @Description: file content
-->
<template>
  <div class="en-title-bar">
    <div class="left" v-if="!hasChecked">{{ title }}</div>
    <slot name="left" v-if="hasChecked"></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name: "EnTitleBar",
  props: {
    title: String,
    hasChecked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.en-title-bar {
  width: 100%;
  height: 50px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e8ecf2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  > div {
    flex: 1;
    text-align: right;
  }
  .left {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #333333;
  }
}
</style>
