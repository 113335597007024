var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mail-list-wrap",
      class: { "empty-wrap": _vm.accountList.length === 0 },
    },
    [
      _vm.accountList.length !== 0
        ? _c(
            "div",
            { staticClass: "left-menu" },
            [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _c(
                    "en-button",
                    {
                      staticClass: "btn-write",
                      attrs: { icon: "xieyoujian", iconSize: 16 },
                      on: { click: _vm.toMailSend },
                    },
                    [_vm._v("写邮件")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "menu-group" }, [
                _c(
                  "div",
                  {
                    staticClass: "menu-item noread active-item",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleNoreadClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "menu-item-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("en-icon", {
                            attrs: {
                              name: "weiduyoujian",
                              color: "#FE7D67",
                              size: "small",
                            },
                          }),
                          _c("span", [_vm._v("未读邮件")]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.noreadNum > 0,
                              expression: "noreadNum > 0",
                            },
                          ],
                          staticClass: "right",
                        },
                        [_vm._v(" " + _vm._s(_vm.noreadNum) + " ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "menu-item book",
                    on: { click: _vm.handleBookClick },
                  },
                  [
                    _c("div", { staticClass: "menu-item-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("en-icon", {
                            attrs: {
                              name: "tongxunlu",
                              color: "#26c393",
                              size: "small",
                            },
                          }),
                          _c("span", [_vm._v("通讯录")]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(
                "draggable",
                {
                  staticClass: "account-group",
                  attrs: { group: "field" },
                  on: { end: _vm.accountMove },
                  model: {
                    value: _vm.accountList,
                    callback: function ($$v) {
                      _vm.accountList = $$v
                    },
                    expression: "accountList",
                  },
                },
                _vm._l(_vm.accountList, function (itm, idx) {
                  return _c("account-mod", {
                    key: itm.id,
                    attrs: { account: itm, index: idx },
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "btn-setting",
                  on: { click: _vm.jumpToSetting },
                },
                [
                  _c(
                    "en-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "shezhi",
                        iconColor: "#aab6c7",
                        iconSize: "14",
                      },
                    },
                    [_vm._v("邮箱设置")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.accountList.length !== 0
        ? _c("div", { staticClass: "right-list" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "en-loading",
                    rawName: "v-en-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "right-list-wrap",
              },
              [
                _c(
                  "div",
                  { staticClass: "title-bar" },
                  [
                    _c(
                      "en-title-bar",
                      {
                        attrs: {
                          title: _vm.mailTitle,
                          hasChecked: _vm.hasCheckedMail,
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "left" },
                          [
                            _vm.hasCheckScreen
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      "popper-class": "checkbox-item-popover",
                                      placement: "bottom",
                                    },
                                    model: {
                                      value: _vm.checkVisible,
                                      callback: function ($$v) {
                                        _vm.checkVisible = $$v
                                      },
                                      expression: "checkVisible",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "btn-list-wrap" },
                                      _vm._l(_vm.screenBtns, function (itm) {
                                        return _c(
                                          "div",
                                          {
                                            key: itm.id,
                                            class: [
                                              "btn-list-item",
                                              itm.readFlag.toString() ===
                                              _vm.seParams.readFlag.toString()
                                                ? "cur"
                                                : "",
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleScreenClick(
                                                  itm.readFlag
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "en-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  icon: itm.icon,
                                                  iconSize: 16,
                                                  iconColor: itm.iconColor,
                                                },
                                              },
                                              [_vm._v(_vm._s(itm.name))]
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            on: { change: _vm.handleCheckAll },
                                            model: {
                                              value: _vm.checkedAll,
                                              callback: function ($$v) {
                                                _vm.checkedAll = $$v
                                              },
                                              expression: "checkedAll",
                                            },
                                          },
                                          [_vm._v("全选")]
                                        ),
                                        _c("i", {
                                          staticClass: "iconfont iconfanhui",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.handleCheckAll },
                                    model: {
                                      value: _vm.checkedAll,
                                      callback: function ($$v) {
                                        _vm.checkedAll = $$v
                                      },
                                      expression: "checkedAll",
                                    },
                                  },
                                  [_vm._v("全选")]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "right" },
                          [
                            _c("buttonMod", {
                              attrs: {
                                state: _vm.seParams.statuses,
                                mailType: _vm.mailType,
                                hasRelatBtn: !!_vm.mappingList.length,
                              },
                            }),
                            _vm.seParams.folderType === 6
                              ? _c("status-popover")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "mail-container",
                    class: [_vm.mailList.length === 0 ? "disflex" : ""],
                  },
                  [
                    _c("en-result", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mailList.length === 0 && !_vm.loading,
                          expression: "mailList.length === 0 && !loading",
                        },
                      ],
                      attrs: { type: "NoData" },
                    }),
                    _vm._l(_vm.mailList, function (itm, idx) {
                      return _c("mail-mod", {
                        key: itm.id,
                        attrs: {
                          index: idx,
                          mailData: itm,
                          "checkbox-visible": _vm.mailList.some(
                            (item) => item.checked
                          ),
                          usedFlg: "list",
                        },
                      })
                    }),
                  ],
                  2
                ),
                _c(
                  "en-pagination",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mailList.length,
                        expression: "mailList.length",
                      },
                    ],
                    attrs: { pageModel: _vm.pageModel },
                    on: { change: _vm.handlePageChanged },
                  },
                  [
                    _c("template", { slot: "left" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkedNum > 0,
                              expression: "checkedNum > 0",
                            },
                          ],
                          staticClass: "checked-tips",
                        },
                        [
                          _c("div", [
                            _vm._v(" 已选"),
                            _c("span", { staticStyle: { color: "#3e90fe" } }, [
                              _vm._v(_vm._s(_vm.checkedNum)),
                            ]),
                            _vm._v("条数据 "),
                          ]),
                          _c(
                            "en-button",
                            {
                              attrs: { type: "text", color: "#3e90fe" },
                              on: { click: _vm.handleCleanChecked },
                            },
                            [_vm._v("清空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.accountList.length === 0 && !_vm.loading
        ? _c("div", { staticClass: "no-account" }, [
            _c("div", { staticClass: "header" }, [_vm._v("企业邮箱")]),
            _c("img", {
              staticClass: "icon-img",
              attrs: {
                src: "https://images.softfuxi.com/static/nodata11310.png",
                alt: "无数据",
              },
            }),
            _c(
              "p",
              { staticClass: "empty-text" },
              [
                _vm._v(" 您当前没有邮箱账号，点击 "),
                _c(
                  "en-button",
                  {
                    attrs: { type: "primary", text: "" },
                    on: { click: _vm.jumpToSetting },
                  },
                  [_vm._v("添加邮箱账号")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("mapping-dialog", {
        ref: "mappingDialog",
        attrs: {
          "mapping-list": _vm.mappingList,
          "relat-visible": _vm.relatVisible,
        },
        on: {
          closeDialog: function ($event) {
            _vm.relatVisible = false
          },
          handleRelatClick: _vm.handleRelatClick,
        },
      }),
      _c("receipt-dialog", { ref: "receiptDialog" }),
      _vm.detailVisible
        ? _c("mailDetail", {
            ref: "mailDetail",
            attrs: {
              needNext: _vm.needNext,
              nextObj: _vm.nextObj,
              businessKey: _vm.businessKey,
              seeflg: _vm.seeflg,
              id: _vm.detailId,
              visible: _vm.detailVisible,
            },
            on: {
              close: function ($event) {
                _vm.detailVisible = false
              },
            },
          })
        : _vm._e(),
      _c("password-dialog", {
        ref: "passwordDialog",
        on: { closeDialog: _vm.closeDialog, submit: _vm.submit },
      }),
      _vm.addMappingflg
        ? _c("Mapping", {
            attrs: {
              id: _vm.mappingIds,
              show: _vm.addMappingflg,
              mappingList: _vm.mappingList,
            },
            on: {
              toShow: function ($event) {
                _vm.addMappingflg = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }