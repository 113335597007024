<template>
  <div class="payment">
    <payment-header :order-info="orderInfo"></payment-header>
    <div class="payment-content"  v-loading="loading">
      <payment-info :order-info="orderInfo"></payment-info>
      <div v-if="!loading">
        <payment-mode :order-info="orderInfo" v-if="payStatus !== 30"></payment-mode>
        <div class="success" v-else>
          <div>
            <img src="../../assets/images/success.png" alt="">
            <p class="success-text">支付成功</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysPaymentService } from "@/api/payment";
import PaymentHeader from "./syscomponents/PaymentHeader";
import PaymentInfo from "./syscomponents/PaymentInfo";
import PaymentMode from "./syscomponents/PaymentMode";

export default {
  name: "SysPayment",
  components: {
    PaymentHeader,
    PaymentInfo,
    PaymentMode
  },
  data() {
    return {
      payStatus: 0,
      // 订单是否存在
      existOrder: true,
      loading: true,
      orderInfo: {
        orderId: "",
        orderAmount: "**"
      }
    };
  },
  watch: {
    payStatus(val) {
      function getElementToPageTop(el) {
        if (el.parentElement) {
          return getElementToPageTop(el.parentElement) + el.offsetTop;
        }
        return el.offsetTop;
      }
      if (val === 30) {
        setTimeout(() => {
          const successDom = document.querySelector(".success");
          console.log(getElementToPageTop(successDom));
          successDom.style.height = `${document.documentElement.clientHeight - getElementToPageTop(successDom) - 10}px`;
        }, 300);
      }
    }
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    }
  },
  methods: {
    async querySysPayOrderInfo() {
      try {
        const res = await sysPaymentService.querySysPayOrderInfo({
          orderId: this.orderId
        });
        Object.assign(this.orderInfo, res);
        console.log(res);
        if (res.showTenantLogo === 1) {
          document.querySelector("link").href = res.tenantLogo.channelIconLogo;
        }
      } catch (error) {
        this.existOrder = false;
      }
    },
    async getPayStatus() {
      const res = await sysPaymentService.querySysPayOrderStatus({
        orderId: this.orderId
      });
      // 支付状态
      // 10-未支付
      // 20-支付中
      // 30-支付成功
      // 40-支付失败
      // 50-订单关闭
      this.payStatus = Number(res.payStatus);
      this.loading = false;
      if (this.payStatus !== 30 && this.existOrder) {
        setTimeout(() => {
          this.getPayStatus();
        }, 5000);
      }
    }
  },
  mounted() {
    console.log(this.orderId);
    this.orderInfo.orderId = this.orderId;
    this.getPayStatus();
    this.querySysPayOrderInfo();
  }
};
</script>

<style lang="scss" scoped>
.payment {
  .payment-content {
    padding: 10px;
    .success {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #e8ecf2;
      text-align: center;
      align-items: center;
      min-height: 572px;
      img {
        margin-bottom: 16px;
        width: 112px;
      }
      .success-text {
        font-size: 12px;
        color: #91a1b7;
        text-indent: 12px;
      }
    }
  }
}
</style>
