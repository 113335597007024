<!--
 * @Author: pengyu
 * @Date: 2021-03-17 17:33:33
 * @LastEditors: pengyu
 * @LastEditTime: 2021-04-20 09:13:14
 * @Description: file content
-->
<template>
  <el-popover
    popper-class="status-screen-popover"
    placement="bottom-start"
    trigger="hover"
    :width="160"
    :offset="-90"
  >
    <template slot="reference">
      <el-button type="white" size="small" class="btn-screen" icon="iconfont iconshaixuan">{{btnName}}</el-button>
    </template>
    <div class="popover-content">
      <el-checkbox-group v-model="res">
        <el-checkbox v-for="(itm, idx) in list" :key="idx" :label="itm.id">{{itm.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: "StatusPopover",
  data() {
    return {
      list: [
        { id: "1", name: "待审批" },
        { id: "2", name: "审批中" },
        { id: "3", name: "未通过" },
        { id: "5", name: "发送失败" }
      ],
      res: []
    };
  },
  watch: {
    res(nVal) {
      if (nVal) {
        this.handleSearch();
      }
    }
  },
  computed: {
    btnName() {
      const arr = this.list.filter((itm) => itm.active);
      if (arr.length) {
        return `${arr.length}项`;
      }
      return "筛选";
    }
  },
  inject: ["$mailList"],
  methods: {
    /**
     * @description: 点击状态 筛选
     */
    handleSearch() {
      this.$mailList.seParams.approveStatus = this.res.join(",");
      this.$mailList.queryMailList();
    }
  }
};
</script>

<style lang='scss' scoped>
.btn-screen{
  border: none;
  font-size: 12px;
  & /deep/ .iconshaixuan{
    color: #9367eb;
  }
  &:hover{
    background: rgba($color: #9367eb, $alpha: 0);
  }
  & /deep/ .iconfont{
    margin-right: 5px !important;
    vertical-align: middle;
  }
  & /deep/ span{
    vertical-align: middle;
  }
}
.popover-content{
  font-size: 12px;
  .el-checkbox{
    padding: 10px;
    width: 100%;
    &:hover{
      background: #F5F8FD;
    }
  }
}

</style>
<style lang="scss">
.status-screen-popover{
  padding: 2px 0;
}
</style>
