var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "main-wrap", class: { "half-screen": !_vm.fullScreen } },
      [
        _c(
          "div",
          { staticClass: "content", class: { "need-half": !_vm.fullScreen } },
          [
            _c(
              "keep-alive",
              { attrs: { include: "MailList" } },
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }